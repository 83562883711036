<template>
<div id="loading" >
    <div id="loading-center">
    </div>
  </div>
</template>
<script>
import {animation} from "@/config/pluginInit";

export default {
  name: 'Loader',
  watch: {
    $route: function () {
      this.init()
    }
  },
  computed: {
  },
  mounted () {
    this.init()
  },
  methods: {
    start: function(_callback) {
      const load = document.getElementById('loading')
      load.classList.remove('d-none')
      window.scrollTo({ top: 0, behavior: 'smooth' });
      animation.fadeIn(load, { duration: 20 })     
    },
    end: function(_callback) {
      const load = document.getElementById('loading')
       setTimeout (() => {
        animation.fadeOut(load, { duration: 20 })
        load.classList.add('d-none')
        //_callback();
      }, 1000)  
    },
    init() {
      const load = document.getElementById('loading')
      load.classList.remove('d-none')
      window.scrollTo({ top: 0, behavior: 'smooth' });
      animation.fadeIn(load, { duration: 20 })
      setTimeout (() => {
        animation.fadeOut(load, { duration: 20 })
        load.classList.add('d-none')
      }, 1000)
    }
  }
}
</script>


<style lang="scss" >
  #loading-center {
    background: url(~@/assets/images/loader-wookie.gif) no-repeat scroll center center;
    background-size: 20%;
    width: 100%;
    height: 100%;
    position: relative;
  }
</style>
